import React, { useState, useEffect } from 'react';
import { Container, Grid, Box,Checkbox, TextField, Button, InputLabel, Select, MenuItem, AlertTitle, FormControl, Card, CardContent, Link, Alert, Typography, Autocomplete } from '@mui/material';
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import {
  createTheme,
  ThemeProvider,

} from "@mui/material/styles";
import { styled } from '@mui/material/styles';
import ToggleButton from '@mui/material/ToggleButton';
import { useNavigate } from "react-router-dom";
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Divider from '@mui/material/Divider';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Modal from '@mui/material/Modal';
import InputAdornment from '@mui/material/InputAdornment';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import FlightIcon from '@mui/icons-material/Flight';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AddIcon from '@mui/icons-material/Add';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import FlightLandIcon from '@mui/icons-material/FlightLand';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import AnchorIcon from '@mui/icons-material/Anchor';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import InventoryIcon from '@mui/icons-material/Inventory';
import { SelectChangeEvent } from '@mui/material/Select';
import { Formik, Form, FieldArray, getIn, ErrorMessage, FormikConsumer, FormikTouched, FormikErrors } from 'formik';
import axios from 'axios';
import { useFormik } from 'formik';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import moment from 'moment';
import * as yup from 'yup';
import { DEFAULTS } from '../../Constants/Constants'
import { getPackageName } from '../../util/DisplayUtils';
import { firebaseApp, analytics } from "../../lib/firebase";
import { logEvent } from "firebase/analytics"
import Finder from "../../portal/components/Common/Finder/Finder"
//import CargoItem from "../../portal/components/Common/CargoItem/CargoItem"
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
//import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Dayjs } from 'dayjs';
import Switch from '@mui/material/Switch';
import { Field, FieldProps } from 'formik';
import { AirOutlined } from '@mui/icons-material';
import { red } from '@mui/material/colors';
import './Login.css';


const defaultTheme = createTheme();
const theme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "2em",
          color: "yellow",
          backgroundColor: "red",
        },
      },
    },
  },
});
const BlueOnGreenTooltip = styled(Tooltip)({
  tooltip: {
    color: "white",
    backgroundColor: '#eb4034',
    padding: 10,
    fontSize: 12
  }
});

type MyState = Array<any> | Record<string, any>;
interface CountryType {
  code: string;
  label: string;
  phone: string;
  suggested?: boolean;
}

interface CountryType {
  code: string;
  label: string;
  phone: string;
  suggested?: boolean;
}
interface sortedData {
}

interface MyType {
  service_code: string;
  service_type: string;
  carrier_delivery_days: number;
  error_messages: string[];
  shipping_amount: {
    amount: number;
    // Other properties related to shipping_amount
  };
  // Other properties
}
interface Weight {
  value: string;
  unit: string;
}

interface Dimensions {
  unit: string;
  length: string;
  width: string;
  height: string;
}

interface InsuredValue {
  amount: number;
}

interface Item {
  quantity: string;
  packaging: string;
  weight: Weight;
  dimensions: Dimensions;
  insured_value: InsuredValue;
}

interface FormValues {
  shipType: string;
  item: Item;
}

interface MyObject {
  weight: {
    value: number,
    unit: string
  },
  dimensions: {
    unit: string,
    length: number,
    width: number,
    height: number
  },
  insured_value: {
    currency: string,
    amount: number
  }
}
interface RateType {
  service_code: string;
  mark_up: string;
  // Other properties related to rates
}
const today = new Date();
// Set the time to midnight (00:00:00)
today.setHours(0, 0, 0, 0);


const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
const positive = {
  color: 'green'
}
const errorStyle = {
  color: 'red',
  fontSize: '9pt',
  marginTop: 2
}

function Super() { return (<span style={{ color: 'red', verticalAlign: 'super' }}>*</span>) }

const itemRecord = {
  "quantity": "",
  "packaging": "CARTON",
  "weight": {
    "value": 1,
    "unit": "ounces"
  },
  "dimensions": {
    "unit": "inch",
    "length": 1,
    "width": 1,
    "height": 1
  },
  "insured_value": {
    "amount": 0
  }
}

const itemRecordDefault = {
  "quantity": "1",
  "packaging": "CARTON",
  "weight": {
    "value": "1",
    "unit": "ounces"
  },
  "dimensions": {
    "unit": "inch",
    "length": "1",
    "width": "1",
    "height": "1"
  },
  "insured_value": {
    "amount": 0
  }
}


const StepOne = (props: any) => {
  const [shipRates, setShipRates] = useState<{ [key: string]: any }>({});
  const [shipErrors, setShipError] = useState(null);
  const [loading, setLoading] = React.useState(false);
  const [isShow, setShow] = React.useState(false);
  const [isError, setError] = React.useState(false);
  const [value, setValue] = React.useState<Dayjs | null>(null);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const countries: CountryType[] = DEFAULTS.COUNTRIES.sort((a: any, b: any) => a.label.localeCompare(b.label));

  const [alignment, setAlignment] = React.useState('air');
  const [load, setLoad] = React.useState('ftl');
  const [customer, setCustomer] = React.useState('');
  const [finderShow, setFinderShow] = React.useState(false);
  const [modalData, setModalData] = React.useState<Record<string, any>>({});
  const navigate = useNavigate();
  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string,
  ) => {
    setAlignment(newAlignment);
  };
  const handleChangeLoad = (
    event: React.MouseEvent<HTMLElement>,
    newLoad: string,
  ) => {
    setLoad(newLoad);
  };

  const postQuote = async (quote: any) => {
    setOpen(true);

    try {
      const response = await axios.post(DEFAULTS.API_URL + 'quote-enquiry', quote);

      if (response.status === 500) {
        console.error('Internal server error occurred');
        setError(true)
      } else {
        console.log("response", response.data)
        setOpen(false);
        if (response.data.status === "success") {
          console.log("seuccess");
          navigate('/auth/complete-quote')
        }
      }
    }
    catch (error) {
      console.error('Error occurred:', error);
      setError(true)
    }

    setOpen(false);
  }

  const handleFinderOpen = () => {
    setFinderShow(true);
  };

  const handleFinderClose = (dataFromModal: any, dataLabel: any, dataAddType: any) => {
    setFinderShow(false);
    console.log("modaldata", dataFromModal, dataLabel, dataAddType)
  };
  const handleSubmit = (values: any, errors: any) => {
    console.log("step 1 values", values, errors)
    props.next(values)

  }

  const validationSchema = yup.object({
    //ship_type: yup.string().required('Ship type is required'),
    load_type: yup.string().test({
      name: 'required-for-ocean-or-truck',
      message: 'Load type is required',
      test: function (value, context) {
        const shipType = context.parent.ship_type; // Accessing ship_type from the parent context
        if (shipType === 'ocean' || shipType === 'truck') {
          // Check if load_type is provided for ocean or truck
          return typeof value === 'string' && value.trim() !== ''; // Return true if load_type is provided and not empty
        }
        return true; // Return true if ship_type is not ocean or truck
      }
    }),
    from_where: yup.string().required('Select an origin'),
    to_where: yup.string().required('Select a destination'),
    ship_name: yup.string().when('ship_type', {
      is: (val: any) => val === 'local-courier',
      then: schema => schema.required('Name is required')
.matches(/^[a-zA-Z\s]+$/, 'Please enter alphabets and spaces'),
      otherwise: schema => schema.nullable()
    }),
      acceptsmsemail: yup
        .boolean()
        .oneOf([true], "You must consent to receiving SMS and email messages")
        .required('You must consent to receiving SMS and email messages'),

    ship_email: yup.string().when('ship_type', {
      is: (val: any) => val === 'local-courier',
      then: schema => schema.required('Email is required')
        .matches(
          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
          'Please enter a valid email address.'
        ),
      otherwise: schema => schema.nullable()
    }),

    ship_mobile: yup.string().when('ship_type', {
      is: (val: any) => val === 'local-courier',
      then: schema => schema.required('Mobile is required')
      .matches(
        /^\+?\d+$/,
        'Please enter a valid mobile number.'
      ),
      otherwise: schema => schema.nullable()
    }),
    ship_type: yup.string().required('Ship type is required'),
    item: yup.object().shape({
      //packaging: yup.string().required('Select a packaging').nullable(),
      // quantity: yup.number().required('Weight is required').min(1, 'Quantity cannot be zero').nullable(),
      weight: yup.object().shape({
      value: yup.number().when('ship_type', {
        is: (val: any) => val === 'local-courier',
        then: schema => schema.required('Weight is required').min(1, 'Quantity cannot be zero').nullable(),
        otherwise: schema => schema.notRequired()
      })
      
    }),
    dimensions: yup.object().shape({
      
      height: yup.number().when('ship_type', {
        is: (val: any) => val === 'local-courier',
        then: schema => schema.required('Height is required').min(1, 'Height cannot be zero').nullable(),
        otherwise: schema => schema.nullable()
      }),
      width: yup.number().when('ship_type', {
        is: (val: any) => val === 'local-courier',
        then: schema => schema.required('Width is required').min(1, 'Width cannot be zero').nullable(),
        otherwise: schema => schema.nullable()
      }),
      length: yup.number().when('ship_type', {
        is: (val: any) => val === 'local-courier',
        then: schema => schema.required('Length is required').min(1, 'Length cannot be zero').nullable(),
        otherwise: schema => schema.nullable()
      })
    })
    })
  
  })


  return (
    <CardContent>

      <Formik
        initialValues={props.data}
        validationSchema={validationSchema}
        // validate={(values) => {
        //   const validationContext = { ship_type: values.ship_type };
        //   return validationSchema.validate(values, { context: validationContext });
        // }}
        onSubmit={handleSubmit}
        render={({ values, isSubmitting, errors, touched, handleChange, handleBlur, isValid, setFieldValue }) => (
          <Form>
            {finderShow && (<Finder onCloseModal={(dataFromModal: any, dataLabel: any, dataAddType: any, dataShip: any) => {
              setFinderShow(false);
              console.log("modaldata", dataFromModal, dataLabel, dataAddType, dataShip)
              if (dataShip === "from_where") {
                setFieldValue('from_where', dataLabel)
                setFieldValue('from_address', dataFromModal)
                setFieldValue('from_type', dataAddType)
              }
              else {
                setFieldValue('to_where', dataLabel)
                setFieldValue('to_address', dataFromModal)
                setFieldValue('to_type', dataAddType)
              }
            }} data={modalData} />)}

            <Grid container spacing={2}   sx={{ 
    marginTop: { xs: 2, sm: 4, md: 5, lg: 10 }, // Apply different margins for different screen sizes
    marginBottom: { xs: 2, sm: 4, md: 5, lg: 10 } 
  }} >
              <Grid item xs={12} sm={12} md={6}
              >
                <Typography variant="h4" style={{ color: 'white' }}>
                  <h1>Simplify Global Shipping with IKEOCEAN!</h1>

                 <p>
                 <h5>Contact IKEOCEAN</h5>
                 info@ikeocean.com<br/>
                   +1 678 261 7328</p>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={6} style={{ backgroundColor: 'white', paddingRight:16 }}>
                <Typography variant="h4" style={{ color: '#eb4034' }}>Get an Instant Quotation</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12}>
                    <InputLabel id="from_whereLaabel">How do you want to ship?</InputLabel>

                    <ToggleButtonGroup
                      color="primary"
                      exclusive
                      id="ship_type"
                      onChange={(event, value) => {
                        setFieldValue('ship_type', value);
                        setFieldValue('load_type', '');
                        setFieldValue('item.weight.ship_type', value);
                        setFieldValue('item.dimensions.ship_type', value);
                      }} // Changed `formik.setFieldValue` to `setFieldValue`
                      value={values.ship_type}
                      aria-label="Platform"
                      autoFocus
                      sx={{
                        "& .MuiToggleButton-root": {

                          color: "black",
                          "&.Mui-selected": {
                            color: "red",

                          },
                        },
                        "& .MuiToggleButton-label": {
                          color: "white",
                        },
                      }}
                    >
                      <ToggleButton value="air"><FlightIcon />Air</ToggleButton>
                      <ToggleButton value="ocean"><DirectionsBoatIcon />Ocean</ToggleButton>
                      <ToggleButton value="truck"><LocalShippingIcon />Truck</ToggleButton>
                      {/* <ToggleButton value="local-courier"><InventoryIcon />Local Courier</ToggleButton> */}
                      {/* <ToggleButton value="courier"><FlightIcon/>Local Courier</ToggleButton> */}
                    </ToggleButtonGroup>
                    {touched.ship_type && errors.ship_type && typeof errors.ship_type === 'string' && (
                      <div style={errorStyle}>{errors.ship_type}</div>
                    )}



                  </Grid>
                  {((values.ship_type === "ocean") || (values.ship_type === "truck")) && (

                    <Grid item xs={12} sm={12} md={12}>
                      <InputLabel id="from_whereLbabel">A full or partial load?</InputLabel>
                      <ToggleButtonGroup
                        color="primary"
                        exclusive
                        id="load_typeToggle"
                        onChange={(event, value) => {
                          setFieldValue('load_type', value);
                          if (value === 'fcl' || value === 'ftl') {
                            values.items = [];
                            values.items.push(itemRecordDefault)
                            console.log("values---", values)
                          }
                        }} // Changed `formik.setFieldValue` to `setFieldValue`
                        value={values.load_type}
                        aria-label="Platform"
                        sx={{
                          "& .MuiToggleButton-root": {

                            color: "black",
                            "&.Mui-selected": {
                              color: "red",

                            },
                          },
                          "& .MuiToggleButton-label": {
                            color: "white",
                          },
                        }}
                      >
                        {(values.ship_type === "truck") && (<><ToggleButton value="ftl">Full (FTL)</ToggleButton>
                          <ToggleButton value="ltl">Partial(LTL)</ToggleButton></>)
                        }
                        {(values.ship_type === "ocean") && (<><ToggleButton value="fcl">Full (FCL)</ToggleButton>
                          <ToggleButton value="lcl">Partial (LCL)</ToggleButton></>)
                        }

                      </ToggleButtonGroup>
                      {touched.load_type && errors.load_type && typeof errors.load_type === 'string' && (
                        <div style={errorStyle}>{errors.load_type}</div>)}
                    </Grid>)

                  }
                  <Grid item xs={12} sm={12} md={6}>
                    
                      <InputLabel id="from_whereLabel">From where?<Super /></InputLabel>
                      <div onClick={() => {
                        setModalData({ headingText: "From where?", shipType: values.ship_type, shipField: "from_where" });
                        if (values.ship_type !== '') { handleFinderOpen() };
                      }}>  <Tooltip title={(values.ship_type === '') && 'Select ship type first'}>
                          <TextField
                            name="from_where"
                            fullWidth
                            //disabled
                            id="from_where"
                            size="small"
                            value={values.from_where}
                            error={touched.from_where && Boolean(errors.from_where)}
                            style={{ backgroundColor: '#e9ecef' }}
                            sx={{
                              color: "Red",
                              "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: "Red",
                              },
                              input: { cursor: 'pointer' }
                            }}
                            InputProps={{
                              placeholder: 'Click to search',
                              startAdornment: (
                                <InputAdornment position="start">
                                  {(values.ship_type === "truck") && <><LocalShippingIcon style={{ cursor: 'pointer !important' }} /></>}
                                  {(values.ship_type === "air") && <FlightIcon style={{ cursor: 'pointer !important' }} />}
                                  {(values.ship_type === "ocean") && <DirectionsBoatIcon style={{ cursor: 'pointer !important' }} />}
                                  {(values.ship_type === "local-courier") && <InventoryIcon style={{ cursor: 'pointer !important' }} />}
                                </InputAdornment>
                              ),
                              endAdornment: touched.from_where && errors.from_where && typeof errors.from_where === 'string' && (
                                <InputAdornment position="end">
                                  <ErrorOutlineIcon color="error" />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Tooltip>
                      </div>
                      {touched.from_where && errors.from_where && typeof errors.from_where === 'string' && (
                        <div style={errorStyle}>{errors.from_where}</div>)}
                 
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    
                      <InputLabel id="to_whereLabel">To where?<Super /></InputLabel>
                      <div onClick={() => {
                        setModalData({ headingText: "To where?", shipType: values.ship_type, shipField: "to_where" });
                        if (values.ship_type !== '') { handleFinderOpen() };
                      }}><Tooltip title={(values.ship_type === '') && 'Select ship type first'} style={{ padding: 40 }}>
                          <TextField
                            name="to_where"
                            fullWidth
                            id="to_where"
                            size="small"

                            //disabled
                            value={values.to_where}
                            error={touched.to_where && Boolean(errors.to_where)}
                            style={{ backgroundColor: '#e9ecef' }}
                            sx={{
                              color: "Red",
                              "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: "Red",
                              },
                              input: { cursor: 'pointer' }
                            }}
                            onClick={() => {
                              setModalData({ headingText: "To where?", shipType: values.ship_type, shipField: "to_where" });
                              if (values.ship_type !== '') { handleFinderOpen() };
                            }}
                            InputProps={{
                              placeholder: 'Click to search',
                              startAdornment: (
                                <InputAdornment position="start">
                                  {(values.ship_type === "truck") && <LocalShippingIcon />}
                                  {(values.ship_type === "air") && <FlightIcon />}
                                  {(values.ship_type === "ocean") && <DirectionsBoatIcon />}
                                  {(values.ship_type === "local-courier") && <InventoryIcon style={{ cursor: 'pointer !important' }} />}
                                </InputAdornment>
                              ),
                              endAdornment: touched.to_where && errors.to_where && typeof errors.to_where === 'string' && (
                                <InputAdornment position="end">
                                  <ErrorOutlineIcon color="error" />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Tooltip>
                      </div>
                      {touched.to_where && errors.to_where && typeof errors.to_where === 'string' && (
                        <div style={errorStyle}>{errors.to_where}</div>)}
                    
                  </Grid>

                  {(values.ship_type === 'local-courier') && (
                    <>
                      {/* <Grid item xs={4} sm={4} md={12}>
                    <Select style={{width:'50%'}}>
                    <MenuItem value="grams">Box or Rigid Packaging</MenuItem>
                    </Select>
                    </Grid> */}


                      <Grid item xs={12} sm={4} md={12}>
                        <InputLabel id="toggle-button-group-label">Dimensions (in inches)<Super /></InputLabel>
                        <Grid container spacing={2} >
                          <Grid item xs={6} sm={4} md={3}>
                            <Field name="item.dimensions.length">
                              {({ field, meta }: FieldProps) => (
                                <TextField
                                  size="small"
                                  autoFocus
                                  fullWidth

                                  placeholder="Length"
                                  {...field}
                                  onChange={handleChange}
                                  value={values.item.dimensions.length}
                                  error={Boolean(
                                    (touched as FormikTouched<FormValues>).item?.dimensions?.length &&
                                    (errors as FormikErrors<FormValues>).item?.dimensions?.length
                                  )}
                                  InputProps={{
                                    endAdornment: (
                                      (touched as FormikTouched<FormValues>).item?.dimensions?.length &&
                                      (errors as FormikErrors<FormValues>).item?.dimensions?.length && (
                                        <InputAdornment position="end">
                                          <ErrorOutlineIcon color="error" />
                                        </InputAdornment>
                                      )
                                    ),
                                  }}
                                />
                              )}
                            </Field>
                            {Boolean(
                              (touched as FormikTouched<FormValues>).item?.dimensions?.length &&
                              (errors as FormikErrors<FormValues>).item?.dimensions?.length
                            ) && (
                                <div style={errorStyle}>
                                  {(errors as FormikErrors<FormValues>).item?.dimensions?.length}
                                </div>
                              )}
                              
                          </Grid>
                          <Grid item xs={6} sm={4} md={3}>
                            <Field name="item.dimensions.width">
                              {({ field, meta }: FieldProps) => (
                                <TextField
                                  size="small"
                                  autoFocus

                                  placeholder="Width"
                                  {...field}
                                  onChange={handleChange}
                                  value={values.item.dimensions.width}
                                  error={Boolean(
                                    (touched as FormikTouched<FormValues>).item?.dimensions?.width &&
                                    (errors as FormikErrors<FormValues>).item?.dimensions?.width
                                  )}
                                  InputProps={{
                                    endAdornment: (
                                      (touched as FormikTouched<FormValues>).item?.dimensions?.width &&
                                      (errors as FormikErrors<FormValues>).item?.dimensions?.width && (
                                        <InputAdornment position="end">
                                          <ErrorOutlineIcon color="error" />
                                        </InputAdornment>
                                      )
                                    ),
                                  }}
                                />
                              )}
                            </Field>
                            {Boolean(
                              (touched as FormikTouched<FormValues>).item?.dimensions?.width &&
                              (errors as FormikErrors<FormValues>).item?.dimensions?.width
                            ) && (
                                <div style={errorStyle}>
                                  {(errors as FormikErrors<FormValues>).item?.dimensions?.width}
                                </div>
                              )}
                          </Grid>
                          <Grid item xs={6} sm={4} md={3}>

                            <Field name="item.dimensions.height">
                              {({ field, meta }: FieldProps) => (
                                <TextField
                                  size="small"
                                  autoFocus

                                  placeholder="Height"
                                  {...field}
                                  onChange={handleChange}
                                  value={values.item.dimensions.height}
                                  error={Boolean(
                                    (touched as FormikTouched<FormValues>).item?.dimensions?.height &&
                                    (errors as FormikErrors<FormValues>).item?.dimensions?.height
                                  )}
                                  InputProps={{
                                    endAdornment: (
                                      (touched as FormikTouched<FormValues>).item?.dimensions?.height &&
                                      (errors as FormikErrors<FormValues>).item?.dimensions?.height && (
                                        <InputAdornment position="end">
                                          <ErrorOutlineIcon color="error" />
                                        </InputAdornment>
                                      )
                                    ),
                                  }}
                                />
                              )}
                            </Field>
                            {Boolean(
                              (touched as FormikTouched<FormValues>).item?.dimensions?.height &&
                              (errors as FormikErrors<FormValues>).item?.dimensions?.height
                            ) && (
                                <div style={errorStyle}>
                                  {(errors as FormikErrors<FormValues>).item?.dimensions?.height}
                                </div>
                              )}
                          </Grid>
                          <Grid item xs={6} sm={4} md={3}>
                            <Field name="item.dimensions.unit">
                              {({ field }: FieldProps) => (
                                <Select
                                  labelId="dimension-label"
                                  size="small"
                                  fullWidth
                                  {...field}
                                >
                                  <MenuItem value="inch">Inch</MenuItem>
                                  <MenuItem value="centimeter">Cm</MenuItem>

                                </Select>
                              )}
                            </Field>
                          </Grid>
                        </Grid>
                      </Grid>


                      <Grid item xs={12} sm={4} md={12}>
                        <InputLabel id="toggle-button-group-label">Weight<Super /></InputLabel>
                        <Grid container spacing={2} >
                        <Grid item xs={6} sm={4} md={3}>

                        <Field name="item.weight.value">
                          {({ field, meta }: FieldProps) => (
                            <TextField
                              size="small"
                              autoFocus

                              placeholder="Weight"
                              {...field}
                              onChange={handleChange}
                              value={values.item.weight.value}
                              error={Boolean(
                                (touched as FormikTouched<FormValues>).item?.weight?.value &&
                                (errors as FormikErrors<FormValues>).item?.weight?.value
                              )}
                              InputProps={{
                                endAdornment: (
                                  (touched as FormikTouched<FormValues>).item?.weight?.value &&
                                  (errors as FormikErrors<FormValues>).item?.weight?.value && (
                                    <InputAdornment position="end">
                                      <ErrorOutlineIcon color="error" />
                                    </InputAdornment>
                                  )
                                ),
                              }}
                            />
                          )}
                        </Field>
                        {Boolean(
                          (touched as FormikTouched<FormValues>).item?.weight?.value &&
                          (errors as FormikErrors<FormValues>).item?.weight?.value
                        ) && (
                            <div style={errorStyle}>
                              {(errors as FormikErrors<FormValues>).item?.weight?.value}
                            </div>
                          )}</Grid>
                          <Grid item xs={6} sm={4} md={3}>
                        <Field name="item.weight.unit">
                          {({ field }: FieldProps) => (
                            <Select
                              labelId="dimension-label"
                              size="small"

                              {...field}
                            >
                              <MenuItem value="gram">Gram</MenuItem>
                              <MenuItem value="kilogram">Kilogram</MenuItem>
                              <MenuItem value="pound">Pound</MenuItem>
                              <MenuItem value="ounce">Ounce</MenuItem>
                            </Select>
                          )}
                        </Field>
                        </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                      
                      <Divider style={{ marginTop: 20, marginBottom: 20 }} />
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={4}>

                          <InputLabel id="from_whereLabel">Your Full Name<Super /></InputLabel>
                          <TextField
                            name="ship_name"
                            fullWidth
                            id="ship_name"
                            size="small"
                            onChange={handleChange}
                            value={values.ship_name}
                            error={touched.ship_name && Boolean(errors.ship_name)}
                            InputProps={{
                              endAdornment: touched.ship_name && errors.ship_name && typeof errors.ship_name === 'string' && (
                                <InputAdornment position="end">
                                  <ErrorOutlineIcon color="error" />
                                </InputAdornment>
                              ),
                            }}
                          />
                          {touched.ship_name && errors.ship_name && typeof errors.ship_name === 'string' && (
                            <div style={errorStyle}>{errors.ship_name}</div>)}

                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>

                          <InputLabel id="from_whereLabel">Your Email<Super /></InputLabel>
                          <TextField
                            name="ship_email"
                            fullWidth
                            id="ship_email"
                            size="small"
                            onChange={handleChange}
                            value={values.ship_email}
                            error={touched.ship_email && Boolean(errors.ship_email)}
                            InputProps={{
                              endAdornment: touched.ship_email && errors.ship_email && typeof errors.ship_email === 'string' && (
                                <InputAdornment position="end">
                                  <ErrorOutlineIcon color="error" />
                                </InputAdornment>
                              ),
                            }}
                          />
                          {touched.ship_email && errors.ship_email && typeof errors.ship_email === 'string' && (
                            <div style={errorStyle}>{errors.ship_email}</div>)}

                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>

                          <InputLabel id="from_whereLabel">Your Phone<Super /></InputLabel>
                          <TextField
                            name="ship_mobile"
                            fullWidth
                            id="ship_mobile"
                            size="small"
                            onChange={handleChange}
                            value={values.ship_mobile}
                            error={touched.ship_mobile && Boolean(errors.ship_mobile)}
                            InputProps={{
                              endAdornment: touched.ship_mobile && errors.ship_mobile && typeof errors.ship_mobile === 'string' && (
                                <InputAdornment position="end">
                                  <ErrorOutlineIcon color="error" />
                                </InputAdornment>
                              ),
                            }}
                          />
                          {touched.ship_mobile && errors.ship_mobile && typeof errors.ship_mobile === 'string' && (
                            <div style={errorStyle}>{errors.ship_mobile}</div>)}

                        </Grid>
                      </Grid>
                      </Grid>
                      </Grid>

                    </>
                  )}

                  <Grid item xs={12} sm={12} md={12}>
                              <Typography
                                        variant="body2">  By clicking "CONTINUE YOUR QUOTE", you consent to receiving SMS and email messages related to your request.
                  Message and data rates may apply for SMS. Message frequency for both SMS and email will vary.<br />
                  Reply "Stop" to opt-out of SMS messaging.<br />
                  Reply "Help" for customer care contact information for SMS.<br />
                  To unsubscribe from email communications, click the "unsubscribe" link in the email<br /></Typography>
                              <FormControlLabel
                                    control={<Checkbox 
                                      value={values.acceptsmsemail}
                                      checked={values.acceptsmsemail}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      id="acceptsmsemail"
                                      name="acceptsmsemail"
                                      color="primary" />}
                                    label={
                                      <Typography
                                        variant="body2"
                                        color="primary"
                                        style={{ cursor: 'pointer', textDecoration: 'underline' }}
                                        
                                      >
                                        <span style={{ textDecoration: 'underline' }}>I have read and agree to the terms above</span>
                                      </Typography>
                                    }
                                  />
                                  {touched.acceptsmsemail && errors.acceptsmsemail && typeof errors.acceptsmsemail === 'string' && (
                                      <div style={errorStyle}>{errors.acceptsmsemail}</div>)}
                  
                                </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                      style={{ backgroundColor: '#eb4034', marginTop: 20, marginBottom: 20 }}
                      disabled={loading}
                    >
                      Continue your quote <ArrowForwardIcon />
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>




            {isError &&
              <Alert severity="error" style={{ marginTop: 10, marginBottom: 10 }}>
                An error has occured during the quote processing. Please try again later or contact our sales team : sales@ikeocean.com<br />
              </Alert>
            }
          </Form>
        )} />


    </CardContent>
  )
}
export default StepOne

