import React, {useState, useEffect, useRef } from 'react';
import { loadStripe } from "@stripe/stripe-js";
import {Container, Button, Card, CardContent,Alert,Typography, Box, RadioGroup, Radio, Grid,CircularProgress} from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from "./PaymentForm";
import TextField from '@mui/material/TextField';
import { useLocation } from "react-router-dom";
import "./App1.css";
import { DEFAULTS } from '../../../../Constants/Constants'
// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
const stripePromise1 = loadStripe("pk_test_51OsttB02aqrDZQO7KBvWYxLDk0tZvV9LLa1m4UVdSXfHEK5CHe5OjqccOnUEL71qn4NCI7gwMFlUgyPNJFXRCvSW006uPcaT8w");
const stripePromise = loadStripe("pk_live_51OsttB02aqrDZQO7E97sinHDuTVTAWbPFio5epMfNXdAnxGo6Lqiy7y0ix4F402n3xVQLpSHITdXoGBhuRIld1hp00beprKbe9");
const token = localStorage.getItem('user-token');
export default function App() {

  const location = useLocation();
  const data = location.state;
  console.log("state", data)

  const [clientSecret, setClientSecret] = useState("");
  const textFieldRef = useRef<HTMLInputElement | null>(null);
  const [walletAmount, setWalletAmount] = useState<number | null>(0);
  const [error, setError] = useState<string | null>(null);
  const [isDisabled, setDisabled] = React.useState(false);

  const getValue = () => {
  
    if (textFieldRef.current) {
      console.log("getValue", textFieldRef.current.value)
      return Number(textFieldRef.current.value);
    }
    return 0;
  };

  const handleChange = (event: React.FocusEvent<HTMLInputElement>) => {
    const inputAmount = event.target.value;
        if (/^\d+$/.test(inputAmount)) {
    if (Number(inputAmount) < 1) {
      setError('Enter amount greater than 0');
      setDisabled(true)
    } else {
      console.log(" session value --------", getValue())
      const walletamount = getValue()
      setWalletAmount(walletamount)
      setError(null);   
      setDisabled(false)
  }
  }
  else{
    setError('Enter a number');
    setDisabled(true)
  }
  console.log(" session value", getValue())
  };
  useEffect(() => {
  setWalletAmount(data.amount)
  setDisabled(false)
  })
  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    !isDisabled && fetch(DEFAULTS.API_URL+"create-payment-intent", {
      method: "POST",
      headers: { "Content-Type": "application/json",
      'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ items: { id: data.orderId, paytype: data.payType, amount: data.amount } }),
    })
      .then((res) => res.json())
      .then((data) => setClientSecret(data.clientSecret));
  }, [walletAmount]);

  const appearance = {
    theme: 'stripe',
  };
  

  const options1 = {
    clientSecret1: 'sk_test_51OsttB02aqrDZQO72rPQmXGCAvb5K0PFBT8DKQyzOlFgB0nY6npslg9k819rwkoYiC0YPMU0TnBvY54KDm3YNVDw00zFVrijTs', // Replace 'your-client-secret' with your actual client secret
    appearance: {
      theme: 'light' // Customize appearance if needed
    }
  };


  const options = {
    clientSecret: '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', // Replace 'your-client-secret' with your actual client secret
    appearance: {
      theme: 'light' // Customize appearance if needed
    }
  };

  return (
    <div>
    <Container>
        <Typography variant="h5">Payment</Typography>
        <Card variant="outlined">
                <CardContent>
                <Grid container spacing={4}>
                <Grid item xs={12} sm={12} md={6}>
                  <p>Amount  : ${Number(data?.amount).toFixed(2)}</p>
                  <p>Payment For : {data.payType}</p>
                  <p>Order Id : {data.orderId}</p>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
      {clientSecret ? (
        <Elements  stripe={stripePromise} options={{clientSecret}} >
          <CheckoutForm />
        </Elements>
      ):<center><CircularProgress /></center>}
      </Grid>
      </Grid>
      </CardContent>
      </Card>
      </Container>
    </div>
  );
}