import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { DEFAULTS } from '../../Constants/Constants';
import axios from 'axios';

const theme = createTheme();

type FormError = {
  status: string;
  msg: string;
};

export default function Verify() {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const type = searchParams.get("type");
  const navigate = useNavigate();
  const [formError, setFormError] = useState<FormError>({ status: '', msg: '' });
  const [loading, setLoading] = useState(true);

  const buildApiUrl = (endpoint: string) => `${DEFAULTS.API_URL}${endpoint}`;

  useEffect(() => {
    const getRecords = async () => {
      if (!id) {
        setFormError({
          status: 'error',
          msg: 'Failed to fetch data. Please try again by clicking the link in the email.',
        });
        setLoading(false);
        return;
      }

      try {
        const response = await axios.post(buildApiUrl('customer/verify'), {
          token: id, 
        });

        const records = response.data;
        setFormError(records);
      } catch (error) {
        console.error('Error fetching records:', error);
        setFormError({
          status: 'error',
          msg: 'Failed to fetch data. Please try again later.',
        });
      } finally {
        setLoading(false);
      }
    };

    getRecords();
  }, [id]);

  const isSuccess = formError?.status === 'success';

  // If type is 'A', show message & redirect to reset/{id} after 3 seconds
  useEffect(() => {
    if (isSuccess && type === 'A') {
      setTimeout(() => {
        navigate(`/auth/reset/${id}`);
      }, 3000);
    }
  }, [isSuccess, type, id, navigate]);

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: 'url(https://ikeocean.com/wp-content/uploads/2020/05/containers-1.jpg)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              {loading ? 'Verifying...' : isSuccess ? 'Verification Successful!' : 'Verification Failed'}
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
              {formError?.msg}
            </Typography>

            {/* Show Login Button if type === 'C' */}
            {isSuccess && type === 'C' && (
              <Button
                type="button"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={() => navigate('/auth/login')}
              >
                Login
              </Button>
            )}

            {/* Show Redirecting Message if type === 'A' */}
            {isSuccess && type === 'A' && (
              <Typography variant="body1" sx={{ mt: 3, mb: 2 }}>
                Redirecting for password change...
              </Typography>
            )}
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
