import React , {useState,useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import {Alert, Grid} from '@mui/material';
import LogoComponent from './LogoComponent'
import LoadingButton from '@mui/lab/LoadingButton';
import CopyRight from './CopyRight'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useFormik } from 'formik';
import * as yup from 'yup';
import axios from 'axios';
import { DEFAULTS } from '../../Constants/Constants'
import Modal from '@mui/material/Modal';
import { firebaseApp, analytics } from "../../lib/firebase";
import { logEvent } from "firebase/analytics";

const theme = createTheme();
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
const validationSchema = yup.object({
  firstname: yup
  .string()
  .min(3, 'First name should atleast contain 3 characters')
  .max(30, 'First name cannot exceed 30 characters')
  .required('Enter first name')
  .matches(/^[a-zA-Z\s]+$/,'Please enter only alphabets'),
  lastname: yup
  .string()
  .min(3, 'Last name should atleast contain 3 characters')
  .max(30, 'Last name cannot exceed 30 characters')
  .required('Enter last name')
  .matches(/^[a-zA-Z\s]+$/,'Please enter only alphabets'),
  mobile: yup
  .string()
  .required('Enter a mobile number') // Field is required
  .matches(
    /^(\+)?\d{10,15}$/,
    'Enter a valid mobile number (10-15 digits, optional "+")'
  )
  .min(10, 'Mobile number must be at least 10 digits') // Minimum length
  .max(15, 'Mobile number cannot exceed 15 digits'), // Maximum length
  company: yup
  .string()
  .min(3, 'Company name should atleast contain 3 characters')
  .max(30, 'Company name cannot exceed 30 characters')
  .matches(/^[a-zA-Z0-9\s]+$/,'Please enter only alphabets and numbers'),
  username: yup
  .string()
  .min(5, 'Email must be at least 5 characters') // Minimum length for valid emails
  .max(50, 'Email must not exceed 50 characters') // Maximum length for email
  .required('Enter an email') // Required field
  .matches(
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]{2,}\.[a-zA-Z]{2,}$/,
    'Enter a valid email (e.g., user@domain.com)'
  ),
  password: yup
  .string()
  .min(6, 'Enter a valid password with atleast 6 characters')
  .max(12, 'Password can be a maximum of 12 characters')
  .required('Enter a password'),
  acceptterms: yup
  .boolean()
  .oneOf([true], "You must accept the terms and conditions")
  .required('Accept terms and conditions'),
  acceptrefundpolicy: yup
  .boolean()
  .oneOf([true], "You must accept the refund policy")
  .required('Accept refund policy'),
  acceptprivacypolicy: yup
  .boolean()
  .oneOf([true], "You must accept the privacy policy")
  .required('Accept privacy policy')

});

export default function SignUp() {
  const navigate = useNavigate();
  const[formError, setFormError] = useState('');
  const [loading, setLoading] = React.useState(false);
  useEffect(() => {
    logEvent(analytics, 'Sign Up', {
content_type: 'page',
content_id: 'signup'
});
}, []);
  const formik = useFormik({
    initialValues: {
      firstname: '',
      lastname: '',
      username: '',
      password: '',
      company:'',
      mobile:'',
      wallet:0,
      createdType:'SELF',
      acceptterms:false,
      acceptrefundpolicy:false,
      acceptprivacypolicy: false

    },
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
     console.log("values", values)
     setLoading(true);
     const expensesListResp = async () => {

      await axios.post(DEFAULTS.API_URL + 'register', values)
      .then(response => {
        console.log("response", response.data);
        if (response.data.status === 'success') {
          const data = { "from": 'SIGNUP' };
          navigate('/auth/confirm-email', { state: data });
        } else {
          setLoading(false);
          setFormError(response.data.msg);
        }
      })
      .catch(error => {
        // Handle errors here
        setLoading(false); // Stop the loading indicator on error
        if (error.response) {
          // The server responded with a status other than 2xx
          const statusCode = error.response.status;
    
           setFormError("An error occurred. Please try again.");
    
        } else {
          // Something else caused the error
          setFormError("An unexpected error occurred. Please try again.");
        }
      });
        
    }
    expensesListResp()
    },
  });
const handleLabelClickRefund = () => {
    // Handle the click event as if it were a link click
    window.open(DEFAULTS.REFUND, '_blank'); // Opens the link in a new tab
  };
  const handleLabelClickTerms = () => {
    // Handle the click event as if it were a link click
    window.open(DEFAULTS.TERMS, '_blank'); // Opens the link in a new tab
  };

  const handleLabelClickPrivacy = () => {
    // Handle the click event as if it were a link click
    window.open(DEFAULTS.PRIVACY, '_blank'); // Opens the link in a new tab
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={8}
          sx={{
            backgroundImage: 'url(https://ikeocean.com/wp-content/uploads/2020/05/png-Road-Cargo.png)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={4} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {/* <LogoComponent/> */}
            <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <Box component="form" onSubmit={formik.handleSubmit} sx={{ mt: 3 }}>
            {(formError.length > 0) ? 
            <Alert color='error' style={{marginBottom:20}}>{formError}</Alert> : ''}
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="firstname"
                  fullWidth
                  id="firstname"
                  label="First Name"
                  autoFocus
                  value={formik.values.firstname}
                  onChange={formik.handleChange}
                  error={formik.touched.firstname && Boolean(formik.errors.firstname)}
                  helperText={formik.touched.firstname && formik.errors.firstname}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="lastname"
                  label="Last Name"
                  name="lastname"
                  value={formik.values.lastname}
                  onChange={formik.handleChange}
                  error={formik.touched.lastname && Boolean(formik.errors.lastname)}
                  helperText={formik.touched.lastname && formik.errors.lastname}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="company"
                  label="Company Name"
                  name="company"
                  value={formik.values.company}
                  onChange={formik.handleChange}
                  error={formik.touched.company && Boolean(formik.errors.company)}
                  helperText={formik.touched.company && formik.errors.company}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="mobile"
                  label="Mobile"
                  name="mobile"
                  value={formik.values.mobile}
                  onChange={formik.handleChange}
                  error={formik.touched.mobile && Boolean(formik.errors.mobile)}
                  helperText={formik.touched.mobile && formik.errors.mobile}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="username"
                  label="Email Address"
                  name="username"
                  value={formik.values.username.toLowerCase()}
                  onChange={formik.handleChange}
                  error={formik.touched.username && Boolean(formik.errors.username)}
                  helperText={formik.touched.username && formik.errors.username}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={formik.touched.password && Boolean(formik.errors.password)}
                  helperText={formik.touched.password && formik.errors.password}
                />
              </Grid>
              <Grid item xs={12}>
              <FormControlLabel
  control={
    <Checkbox
      value={formik.values.acceptterms}
      id="acceptterms"
      name="acceptterms"
      checked={formik.values.acceptterms}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      color="primary"
    />
  }
  label={
    <Typography
      variant="body2"
      color="primary"
      style={{ cursor: 'pointer', textDecoration: 'underline' }}
      onClick={handleLabelClickTerms}
    >
      I have read and agree to the{' '}
      <span style={{ textDecoration: 'underline' }}> terms and conditions</span>
    </Typography>
  }
/>
{formik.touched.acceptterms && formik.errors.acceptterms && (
  <FormHelperText error>{formik.errors.acceptterms}</FormHelperText>
)}
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox 
                    value={formik.values.acceptrefundpolicy}
                    checked={formik.values.acceptrefundpolicy}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    id="acceptrefundpolicy"
                    name="acceptrefundpolicy"
                    color="primary" />}
                  label={
                    <Typography
                      variant="body2"
                      color="primary"
                      style={{ cursor: 'pointer', textDecoration: 'underline' }}
                      onClick={handleLabelClickRefund}
                    >
                      I have read and agree to the{' '}
                      <span style={{ textDecoration: 'underline' }}> refund policy</span>
                    </Typography>
                  }
                />
                {formik.touched.acceptrefundpolicy && formik.errors.acceptrefundpolicy && (
  <FormHelperText error>{formik.errors.acceptrefundpolicy}</FormHelperText>
)}
              </Grid> 
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox 
                    value={formik.values.acceptprivacypolicy}
                    checked={formik.values.acceptprivacypolicy}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    id="acceptprivacypolicy"
                    name="acceptprivacypolicy"
                    color="primary" />}
                  label={
                    <Typography
                      variant="body2"
                      color="primary"
                      style={{ cursor: 'pointer', textDecoration: 'underline' }}
                      onClick={handleLabelClickPrivacy}
                    >
                      I have read and agree to the{' '}
                      <span style={{ textDecoration: 'underline' }}> privacy policy</span>
                    </Typography>
                  }
                />
                {formik.touched.acceptprivacypolicy && formik.errors.acceptprivacypolicy && (
  <FormHelperText error>{formik.errors.acceptprivacypolicy}</FormHelperText>
)}
              </Grid> 
            </Grid>
            <LoadingButton
              type="submit"
              fullWidth
              loading={loading}
              variant="contained"
              disabled={loading}
              sx={{ mt: 3, mb: 2 }}
              style={{backgroundColor:'#eb4034'}}

            >
              Sign Up
              </LoadingButton>
            <Grid container>
                <Grid item xs={12} sm={6}>
                  <Link href="/auth/forgot-password" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Link href="/auth/login" variant="body2">
                   Already have an account? Sign in
                  </Link>
                </Grid>
              </Grid>
              {/* <CopyRight sx={{ mt: 5 }} /> */}
          </Box>
          </Box>
        </Grid>
      </Grid>

      
    </ThemeProvider>
  );
}