import React, { useState, useEffect } from 'react';
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  Divider,
  Link,
  Paper,
  Typography
} from '@mui/material';
import {
  Dashboard as DashboardIcon,
  RequestQuote as RequestQuoteIcon,
  Receipt as ReceiptIcon,
  ExpandLess,
  ExpandMore,
  LiveHelp as LiveHelpIcon,
  Home as HomeIcon,
} from '@mui/icons-material';
import { RootState } from '../../../../redux/store';
import { useSelector } from 'react-redux'

const Navigation = () => {
  const [open, setOpen] = useState(true);
  const { appuserdata } = useSelector((state: RootState) => state.appuserdata);
  const [profileData, setProfileData] = useState(appuserdata);

  // Update profileData when appuserdata changes
  useEffect(() => {
    if (appuserdata) {
      setProfileData(appuserdata);
    }
  }, [appuserdata]);
  

  const handleClick = () => {
    setOpen(!open);
  };


  return (
    <div style={{ height: '95vh' }}>
      <List>
        {/* Dashboard */}
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon sx={{ minWidth: 35 }}>
              <DashboardIcon style={{ color: '#ED1C24' }} />
            </ListItemIcon>
            <Link href="/portal/dashboard" underline="none" color="inherit" sx={{ ml: -1 }}>
              <ListItemText primary="Dashboard" />
            </Link>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon sx={{ minWidth: 35 }}>
              <RequestQuoteIcon style={{ color: '#ED1C24' }} />
            </ListItemIcon>
            <Link href="/portal/quote" underline="none" color="inherit" sx={{ ml: -1 }}>
                <ListItemText primary="Create Quote" />
              </Link>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon sx={{ minWidth: 35 }}>
            <RequestQuoteIcon style={{ color: '#ED1C24' }} />
            </ListItemIcon>
            <Link href="/portal/quotes" underline="none" color="inherit" sx={{ ml: -1 }}>
                <ListItemText primary="My Quotes" />
              </Link>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon sx={{ minWidth: 35 }}>
              <ReceiptIcon style={{ color: '#ED1C24' }} />
            </ListItemIcon>
            <Link href="/portal/orders" underline="none" color="inherit" sx={{ ml: -1 }}>
                <ListItemText primary="My Orders" />
              </Link>
          </ListItemButton>
        </ListItem>


<Divider />

        {/* Help */}
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon sx={{ minWidth: 35 }}>
              <LiveHelpIcon style={{ color: '#ED1C24' }} />
            </ListItemIcon>
            <Link href="/portal/help" underline="none" color="inherit" sx={{ ml: -1 }}>
              <ListItemText primary="Help" />
            </Link>
          </ListItemButton>
        </ListItem>
        
        {/* External Link */}
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon sx={{ minWidth: 35 }}>
              <HomeIcon style={{ color: '#ED1C24' }} />
            </ListItemIcon>
            <Link
              href="https://ikeocean.com"
              underline="none"
              color="inherit"
              target="_blank"
              rel="noopener noreferrer"
              sx={{ ml: -1 }}
            >
              <ListItemText primary="ikeocean.com" />
            </Link>
          </ListItemButton>
        </ListItem>
        <Divider />
        <ListItem>
        <div style={{padding:10, borderRadius:10, backgroundColor:'#f5f2e9'}}>
               <Typography component="p" variant="body1" style={{ marginBottom: '20px', fontWeight: 'bold' }}>Logistics Coordinator</Typography>
               {profileData?.agent ? <>
               <Typography component="p" variant="body1">{profileData?.agent?.userProfileName}</Typography>
               <Typography component="p" variant="body1"><a href={`mailto:${profileData?.agent?.username}`}>{profileData?.agent?.username}</a></Typography>
               <Typography component="p" variant="body1"><a href={`tel:${profileData?.agent?.mobile}`}>{profileData?.agent?.mobile}</a></Typography>
               </> : <>
               <Typography component="p" variant="body1">Logistics Coordinator will be assigned shortly </Typography>
               </>}
               </div>      
        </ListItem>
        
      </List>
    </div>
  );
};

export default Navigation;
