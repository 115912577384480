import * as React from "react";
import { useTheme, useMediaQuery } from "@mui/material";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import {
  LocalShipping as LocalShippingIcon,
  RequestQuote as RequestQuoteIcon,
  AccountCircle as AccountCircleIcon,
  PostAdd as PostAddIcon,
} from "@mui/icons-material";

type BoxComponentProps = {
  boxFor: string;
};

const boxArray = [
  {
    type: "Orders",
    icon: <LocalShippingIcon style={{ color: "#ED1C24", fontSize: "40px" }} />,
    text: "Orders",
    link: "/portal/orders",
    linkText: "View Orders",
  },
  {
    type: "Quotes",
    icon: <RequestQuoteIcon style={{ color: "#1C8CED", fontSize: "40px" }} />,
    text: "Quotes",
    link: "/portal/quotes",
    linkText: "View Quotes",
  },
  {
    type: "Quote",
    icon: <PostAddIcon style={{ color: "#28A745", fontSize: "40px" }} />,
    text: "Quote",
    link: "/portal/quote",
    linkText: "Create New Quote",
  },
  {
    type: "Profile",
    icon: <AccountCircleIcon style={{ color: "#28A745", fontSize: "40px" }} />,
    text: "Profile",
    link: "/portal/profile",
    linkText: "Manage Profile",
  },
];

export default function BoxComponent({ boxFor }: BoxComponentProps) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md")); // Detect desktop screens

  // Find the correct box
  const filteredBox = boxArray.find((box) => box.type === boxFor);
  if (!filteredBox) return null;

  return (
    <Paper
      sx={{
        p: 2,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: 150,
        borderRadius: "15px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        backgroundColor: "#fff",
        color: "#333",
        overflow: "hidden",
        textAlign: "center",
      }}
    >
      {/* Icon */}
      <div>{filteredBox.icon}</div>

      {/* Show text only in desktop view */}
      {isDesktop && (
        <Typography variant="h6" sx={{ mt: 1 }}>
          {filteredBox.text}
        </Typography>
      )}

      {/* Link text (always visible) */}
      <Typography variant="body1">
        <Link color="primary" href={filteredBox.link}>
          {filteredBox.linkText}
        </Link>
      </Typography>
    </Paper>
  );
}
