import React, {useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import Container from '@mui/material/Container';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid'; // Grid version 1
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import AccountCircle from '@mui/icons-material/AccountCircle';
import WalletIcon from '@mui/icons-material/Wallet';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Divider from '@mui/material/Divider';
import axios from 'axios';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Drawer from '@mui/material/Drawer';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { purple } from '@mui/material/colors';
import pic from '../../../../assets/images/ikeocean-300x60white.png';
import { useAppDispatch, useAppSelector} from '../../../../hooks'

import {DEFAULTS} from '../../../../Constants/Constants'
import { useSelector } from 'react-redux'
import store from  '../../../../redux/store'
import { RootState } from '../../../../redux/store';

  interface Props {
    window: Window;
  }
  const drawerWidth = 240;
  // Destructure 'window' from 'Props'
  type customerProfileType = {
    [key: string]: any;
  }
  
const theme = createTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: '#fcfafa',
    },
    secondary: {
      // This is green.A700 as hex.
      main: '#f44336',
    },
  },
});

function Header(props: Props) {
  const { window } = props;
    
    const [mobileOpen, setMobileOpen] = React.useState(false);
  
    const handleDrawerToggle = () => {
      setMobileOpen((prevState) => !prevState);
    };

  const { appuserdata,  error } = useSelector((state: RootState) => state.appuserdata);
  const [profileData, setProfileData] = useState(appuserdata)
  console
  .log(" header appuserdata",appuserdata)


  // Update profileData when appuserdata changes
  useEffect(() => {
    if (appuserdata) {
      setProfileData(appuserdata);
    }
  }, [appuserdata]);

  const navigate = useNavigate();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
    const [auth, setAuth] = React.useState(true);
    const [help,setHelp]=React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    type Anchor = 'top' | 'left' | 'bottom' | 'right';
    const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setAuth(event.target.checked);
    };
  
    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    const handleLogout = () => {
      console.log("logout")
      handleClose()
      localStorage.removeItem('user-token');
      navigate('/auth/logout')
    };
    const handleProfile = () => {
      console.log("logout")
      handleClose()
      navigate('/portal/profile')
    };
    const handleCompany = () => {
      console.log("logout")
      handleClose()
      navigate('/portal/company')
    };

    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const drawer = (
      <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
        {/* <Typography variant="h6" sx={{ my: 2 }}>
          MUI
        </Typography>
        <Divider /> */}
        <List>
          
            <ListItem>
              <ListItemButton sx={{ textAlign: 'center' }}>
                <Link href='/portal/dashboard'>Dashboard</Link>
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton sx={{ textAlign: 'center' }}>
              <Link href='/portal/quote'>Create Quote</Link>
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton sx={{ textAlign: 'center' }}>
              <Link href='/portal/quotes'>My Quotes</Link>
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton sx={{ textAlign: 'center' }}>
              <Link href='/portal/orders'>My Orders</Link>
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton sx={{ textAlign: 'center' }}>
              <Link href='/portal/help'>Help</Link>
              </ListItemButton>
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemButton sx={{ textAlign: 'center' }}>
              <Link href='/portal/profile'>Profile</Link>
              </ListItemButton>
            </ListItem>

            <ListItem>
              <ListItemButton sx={{ textAlign: 'center' }}>
              <Link href='/auth/logout'>Logout</Link>
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton sx={{ textAlign: 'center' }}>
              <Link href='https://ikeocean.com'>ikeocean.com</Link>
              </ListItemButton>
            </ListItem>
            <ListItem>
        <div style={{padding:10, borderRadius:10, backgroundColor:'#f5f2e9'}}>
               <Typography component="p" variant="body1" style={{ marginBottom: '20px', fontWeight: 'bold' }}>Logistics Coordinator</Typography>
               {profileData?.agent ? <>
               <Typography component="p" variant="body1">{profileData?.agent?.userProfileName}</Typography>
               <Typography component="p" variant="body1"><a href={`mailto:${profileData?.agent?.username}`}>{profileData?.agent?.username}</a></Typography>
               <Typography component="p" variant="body1"><a href={`tel:${profileData?.agent?.mobile}`}>{profileData?.agent?.mobile}</a></Typography>
               </> : <>
               <Typography component="p" variant="body1">Logistics Coordinator will be assigned shortly </Typography>
               </>}
               </div>      
        </ListItem>
        </List>
      </Box>
    );
    const container = window !== undefined ? window.document.body : undefined;


    return (
        <Box sx={{ flexGrow: 1 }}>
          
      <AppBar position="static" color='primary'>
        <Toolbar>
        <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
           <img src={pic} />
          </Typography>

          <Box sx={{ display: { xs: 'none', sm: 'block' } }}> 
            {/*<IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
              >
                <NotificationsIcon style={{color:'#ED1C24'}}/>
              </IconButton>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
                onClick={toggleDrawer('right', true)}
              >
                <WalletIcon style={{color:'#ED1C24'}}/>
              </IconButton>*/}
            <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleClick}
                color="inherit"
              >
                <AccountCircle style={{color:'#ED1C24'}}/>
              </IconButton>
        <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem style={{color:'#ED1C24'}}>Howdy! {profileData?.firstname}</MenuItem>
        <MenuItem onClick={handleProfile}>Profile</MenuItem>
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
             
            </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
      <Drawer
            anchor={'right'}
            open={state['right']}
            onClose={toggleDrawer('right', false)}
          >
            {DEFAULTS.MARKUP.map((item, index) => (
  <>
    <p>{item.name}</p>
  </>
))}
          </Drawer>
    </Box>
    );
  }

  

  export default Header;