import React, {useState, useEffect} from 'react';
import { Container, Grid,Box, TextField, Button, InputLabel, Select, MenuItem,AlertTitle, FormControl, Card,CardContent, Link, Alert, Typography, Autocomplete } from '@mui/material';
import { BrowserRouter, Routes, Route,Outlet, useNavigate, useParams } from "react-router-dom";
import Divider from '@mui/material/Divider';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import LinearProgress from '@mui/material/LinearProgress';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import Modal from '@mui/material/Modal';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import FlightIcon from '@mui/icons-material/Flight';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AddIcon from '@mui/icons-material/Add';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InputAdornment from '@mui/material/InputAdornment';
import axios from 'axios';
import ToggleButton from '@mui/material/ToggleButton';

import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useFormik } from 'formik';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import moment from 'moment';
import * as yup from 'yup';
import { Formik, Form, Field, FieldArray, getIn, ErrorMessage, FormikConsumer } from 'formik';
import { DEFAULTS } from '../../../../Constants/Constants'
import {convertFirestoreTimestampToDate,getShipDate} from '../../../../util/DisplayUtils'
type MyState = Array<any> | Record<string, any>;


const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
const positive = {
  color:'green'
}
const errorStyle ={
  color:'red',
  fontSize:'9pt',
  marginTop: 2
}

function Super() {return(<span style={{ color: 'red', verticalAlign: 'super' }}>*</span>)}


function OrderDetail() {

  const pre = (ship_type: any) => {
    switch (ship_type) {
      case 'air':
        return "A1";
      case 'ocean':
        return "S1";
      default:
        return "T1";
    }
  };
  const payStatus =['PENDING PAYMENT', 'DECLINED PAYMENT']
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState<{[key: string]: any}>({});
  const [zohoQuote, setZohoQuote] = useState<{[key: string]: any}>({});
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);
  const [openAlert, setOpenAlert] = useState(false);
  //const [zohoQuote, setZohoQuote] = useState([key: string])
  const token = localStorage.getItem('user-token');
  let navigate = useNavigate();
  const getOrder = async () => {
  await axios.get(DEFAULTS.API_URL+`v1/customer/order/${id}`,
  {headers: {
    'Authorization': `Bearer ${token}`
  }}
  )
  .then(
    response => {
      console.log(response)
      if (response.data)
      {
        setOrder(response.data)
        //getZohoQuote(response.data.zohoQuoteId)
      }
    }
  )
    
}
const handleSubmit = ()=>{

}

const createOrderInvoice = async () => {
  try {
    const response = await axios.post(
      DEFAULTS.API_URL + "generate-pdf",
      order,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: "blob", // Ensures the response is treated as a binary file
      }
    );

    // Create a Blob from the PDF response
    const blob = new Blob([response.data], { type: "application/pdf" });

    // Create a temporary URL for the Blob
    const url = window.URL.createObjectURL(blob);
    setPdfUrl(url); // Store URL in state
    setOpenAlert(true); // Show alert after setting the URL

    // Trigger file download
    const a = document.createElement("a");
    a.href = url;
    a.download = formatOrderId(order?.orderDate, order?.orderId); // Set desired file name
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    console.log("PDF downloaded successfully:", url);
  } catch (error) {
    console.error("Error generating PDF:", error);
  }
};




function formatOrderId(orderDate: any, orderId: any) {
  // Convert Firestore timestamp to JavaScript Date
  const date = new Date(orderDate._seconds * 1000);
  
  // Format the date as YYYYMMDD
  const formattedDate = date.toISOString().slice(0, 10).replace(/-/g, "");

  // Ensure order ID is always 3 digits (zero-padded)
  const formattedOrderId = String(orderId).padStart(3, "0");

  return `SHIP-ORD-${formattedDate}-${formattedOrderId}.pdf`;
}


const makePayment = ()=> {

  const data ={
  "amount":Number(order?.orderTotal),
  "payType":"ORDER",
  "orderId":order?.orderId
  }
  
  //console.log("handlePaybutton",getValue())
  navigate('/portal/pay', { state: data });
}



useEffect(() => {



getOrder()


},[id])




const itemRecord= {}
    return (
      <div>
        
        <Container>
        <Typography variant="h5">Order # : { order?.orderId}</Typography>
            <Card variant="outlined">
                <CardContent>
                {(Object.keys(order).length !== 0) && <Grid container spacing={4}>

                <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">Order Date</span><br />
                  {convertFirestoreTimestampToDate(order?.orderDate)} </Grid>
                <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">Order Status</span><br />
                  {order?.orderStatus?.toUpperCase()}</Grid>
                  <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">Ship Date</span><br />
{getShipDate(order?.orderDetails?.ship_date)}

                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                  <span className="Qhead">Payment Status </span><br />
                {order?.orderPaymentStatus}</Grid>

                <Grid item xs={12} sm={12} md={12}>
                <Divider style={{color:'red'}}/>
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">Customer Mobile</span><br />
                {order?.orderDetails?.ship_mobile}</Grid>
                <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">Customer Name</span><br />
                  {order?.orderDetails?.ship_name?.toUpperCase()}</Grid>
                <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">Customer Email</span><br />
                  {order?.orderDetails?.ship_email?.toUpperCase()}</Grid>
                  <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">Requester</span><br />
{order?.orderDetails?.requester_type?.toUpperCase()}
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                <Divider style={{color:'red'}}/>
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">Shipment Type | Load Type</span><br />
                {order?.orderDetails?.ship_type.toUpperCase()} | {order?.orderDetails?.load_type.toUpperCase()}</Grid>
                <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">Commodity</span><br />
                  {order?.orderDetails?.commodity.toUpperCase()}</Grid>
                <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">Inco Term</span><br />
                {order?.orderDetails?.inco_term?.toUpperCase()}

                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">Currency</span><br />
{order?.orderDetails?.currency?.toUpperCase()}
                </Grid>
                

                <Grid item xs={12} sm={12} md={12}>
                <Divider style={{color:'red'}}/>
                </Grid>
               
                <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">From</span><br />
                {order?.orderDetails?.from_type.toUpperCase()}

                

                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">From Address</span><br />
                {order?.orderDetails?.from_where.toUpperCase()}
                

                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">To</span><br />
                {order?.orderDetails?.to_type.toUpperCase()}

                

                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">To Address</span><br />
                {order?.orderDetails?.to_where.toUpperCase()}
                

                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                <Divider style={{color:'red'}}/>
                </Grid>

                {/* {(order?.quoteType != 'local-courier') && (<Grid item xs={12} sm={12} md={4}>
                <span className="Qhead">Additionals</span><br />
                <table width='100%' cellPadding={2} cellSpacing={2} border={1} style={{borderCollapse: 'collapse'}}>
                {order?.orderDetails?.additionals?.map((item: any, index: number) => (
                 <tr><td>{item.label}</td><td>{item.required? 'YES':'NO'}</td></tr>
                 ))}
</table>
                </Grid>)} */}
                

                {(order?.quoteType === 'local-courier') && <Grid item xs={12} sm={12} md={12}>
                  <span className="Qhead">Cargo Details</span><br /><br />
                  <table width='100%' cellPadding={2} cellSpacing={2} border={1} style={{borderCollapse: 'collapse'}}>
                    <tr>
                      <td>Quantity</td>
                      <td>Packaging</td>
                      <td>Weight</td>
                      <td>Dimensions (H X W X L)</td>
                      </tr>
 
    <tr >
      <td>{order?.orderDetails?.item?.quantity}</td>
                      <td>{order?.orderDetails?.item?.packaging}</td>
                      <td>{order?.orderDetails?.item?.weight.value}  {order?.orderDetails?.item?.weight.unit}</td>
                      <td>{order?.orderDetails?.item?.dimensions.height} X {order?.orderDetails?.item?.dimensions.width} X {order?.orderDetails?.item?.dimensions.length} {order?.orderDetails?.item?.dimensions.unit}</td>
  </tr>

</table></Grid>}
{ ((order?.orderDetails?.ship_type ==='air' || order?.orderDetails?.ship_type ==='courier') || 
              ((order?.orderDetails?.ship_type ==='ocean') && (order?.orderDetails?.load_type ==='lcl')) || 
              ((order?.orderDetails?.ship_type ==='truck') && (order?.orderDetails?.load_type ==='ltl'))) && <Grid item xs={12} sm={12} md={12}>
                  <span className="Qhead">Cargo Details</span><br /><br />
                  <table width='100%' cellPadding={2} cellSpacing={2} border={1} style={{borderCollapse: 'collapse'}}>
                    <tr>
                    <td>Packaging</td>
                      <td>Quantity</td>
                      
                      <td>Weight</td>
                      <td>Dimensions (H X W X L)</td>
                      {/* <td>Rate($)</td>
                      <td>Margin(%)</td> */}
                      <td>Rate ($)</td>
                      <td>Total($)</td>
                      </tr>
  {order?.orderDetails?.items?.map((item: any, index: number) => (
    <tr key={index}>
     
     <td>{Number(item.quantity) > 1 ? `${item.packaging}S` : item.packaging}</td>
                      <td>{item.quantity}</td>
                      <td>{item.weight.value}  {item.weight.unit}</td>
                      <td>{item.dimensions.height} X {item.dimensions.width} X {item.dimensions.length} {item.dimensions.unit}</td>
                      {/* <td>{item.rate}</td>
                      <td>{item.margin}</td> */}
                      <td>{item.marginrate}</td>
                      <td>{item.ratetotal}</td>
                      </tr>
  ))}
  <tr><td colSpan={5}>Cargo Total($)</td><td>{order?.orderDetails?.totalCargo}</td></tr>
</table></Grid>}

{(order?.orderDetails?.load_type === 'fcl' || order?.orderDetails?.load_type === 'ftl') && <Grid item xs={12} sm={12} md={12}>
<span className="Qhead">Cargo Details</span><br /><br />
<table width='100%' cellPadding={2} cellSpacing={2} border={1} style={{borderCollapse: 'collapse'}}>
                    <tr>
                      <td>Container</td>
                      <td>Container Type</td>
                      <td>Commodity</td>
                      <td>Weight</td>
                      <td>Quantity</td>
                      {/* <td>Rate($)</td>
                      <td>Margin(%)</td> */}
                      <td>Rate($)</td>
                      <td>Total($)</td>
                      </tr>
                      <tr> 
                      
                    <td>{order?.orderDetails?.full_load_container}</td>
                    <td>{order?.orderDetails?.full_load_container_type}</td>

                    
                    <td>{order?.orderDetails?.commodity}</td>
                    <td>{order?.orderDetails?.full_load_weight} - {order?.orderDetails?.full_load_weight_unit}</td>
                    <td>{order?.orderDetails?.full_load_quantity}</td>
                    {/* <td>{order?.orderDetails?.full_load_rate}</td>
                    <td>{order?.orderDetails?.full_load_margin}</td> */}
                    <td>{order?.orderDetails?.full_load_marginrate}</td>
                    <td>{order?.orderDetails?.full_load_ratetotal}</td>
                    </tr>
                      </table>
  
  

  
  
  
</Grid>}  



{(order?.quoteType != 'local-courier' && (Number(order?.orderDetails?.totalAdditionals)> 0)) && (<Grid item xs={12} sm={12} md={12}>
                <span className="Qhead">Additionals</span><br />
                <table width='100%' cellPadding={2} cellSpacing={2} border={1} style={{borderCollapse: 'collapse'}}>
                <tr>
                      <td>Code</td>
                      <td>Label</td>
                      <td>Quantity</td>
                      {/* <td>Rate($)</td>
                      <td>Margin(%)</td> */}
                      <td>Rate($)</td>
                      <td>Total($)</td>
                      </tr>
                {order?.orderDetails?.additionalItems?.map((item: any, index: number) => (
                 <tr>
                  <td>{item.code}</td>
                  <td>{item.label}</td>
                  <td>{item.quantity}</td>
                  {/* <td>{item.rate}</td>
                  <td>{item.margin}</td> */}
                  <td>{item.marginrate}</td>
                  <td>{item.ratetotal}</td>
                </tr>
                 ))}
                 <tr><td colSpan={4}>Additionals Total($)</td><td>{order?.orderDetails?.totalAdditionals}</td></tr>
</table>
                </Grid>)}
                <Grid item xs={12} sm={12} md={12}>
                <table width='100%' cellPadding={2} cellSpacing={2} border={1} style={{borderCollapse: 'collapse'}}>
                <tr><td width={'90%'}>Credit/debit card processing fee(3.5%)</td><td>{Number(order?.orderPayCharges).toFixed(2)}</td></tr>
                <tr><td width={'90%'}>Grand Total($)</td><td>{Number(order?.orderTotal).toFixed(2)}</td></tr>
               </table>
                
                <Divider style={{ marginTop: 20, marginBottom: 20 }}/>
                {(order?.orderStatus !== "COMPLETE") && <Button
                  variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    style={{ backgroundColor: '#eb4034', marginTop: 20, marginBottom: 20 }}
                    onClick={makePayment}
                    disabled={payStatus.includes(order?.orderPaymentStatus)? false: true}
                  >
                    Make Payment for ${order?.orderTotal}
                  </Button>}
                  <Button
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
        style={{ backgroundColor: "green", marginTop: 20, marginBottom: 20, marginLeft: 20 }}
        onClick={createOrderInvoice}
        disabled={payStatus.includes(order?.orderPaymentStatus)}
      >
        Download Invoice
      </Button>
                
                </Grid>

                

                </Grid>}
              {/* Show Alert when PDF is generated */}
      {openAlert && (
        <Alert severity="success" onClose={() => setOpenAlert(false)} sx={{ mt: 2 }}>
          PDF downloaded successfully!{" "}
          {pdfUrl && (
            <a href={pdfUrl} target="_blank" rel="noopener noreferrer" style={{ color: "blue", textDecoration: "underline" }}>
              Click here to view
            </a>
          )}
        </Alert>
      )}

</CardContent>
</Card>

<Modal
  open={open}
  onClose={(event, reason) => {
    if (reason !== 'backdropClick') {
      handleClose();
    }
  }}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={style}>
    <Typography id="modal-modal-title" variant="h6" component="h2">
     
    </Typography>
    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
      <p>Sit back while we process your Order.</p>
      <p><LinearProgress color="inherit" /></p>
    </Typography>
  </Box>
</Modal>
      </Container>

      </div>
    );
  }

  interface CountryType {
    code: string;
    label: string;
    phone: string;
    suggested?: boolean;
  }
  
  
  export default OrderDetail;