import React , {useState,useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import {Alert, Grid} from '@mui/material';
import LogoComponent from './LogoComponent'
import CopyRight from './CopyRight'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useFormik } from 'formik';
import * as yup from 'yup';
import axios from 'axios';
import { DEFAULTS } from '../../Constants/Constants'
import Modal from '@mui/material/Modal';
import { firebaseApp, analytics } from "../../lib/firebase";
import { logEvent } from "firebase/analytics";

const theme = createTheme();
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
const validationSchema = yup.object({
  firstname: yup
  .string()
  .min(3, 'First name should atleast contain 3 characters')
  .max(30, 'First name cannot exceed 30 characters')
  .required('Enter first name')
  .matches(/^[a-zA-Z\s]+$/,'Please enter only alphabets'),
  lastname: yup
  .string()
  .min(3, 'Last name should atleast contain 3 characters')
  .max(30, 'Last name cannot exceed 30 characters')
  .required('Enter last name')
  .matches(/^[a-zA-Z\s]+$/,'Please enter only alphabets'),
  mobile: yup
    .string()
    .required('Enter a mobile number') // Field is required
    .matches(
      /^(\+)?\d{10,15}$/,
      'Enter a valid mobile number (10-15 digits, optional "+")'
    ),
  address: yup
  .string()
  .required('Enter State')
  .min(3, 'State should atleast contain 3 characters')
  .max(30, 'State cannot exceed 30 characters')
  .matches(/^[a-zA-Z\s]+$/,'Please enter only alphabets'),
  country: yup
  .string()
  .required('Enter Country')
  .min(3, 'Country should atleast contain 3 characters')
  .max(30, 'Country cannot exceed 30 characters')
  .matches(/^[a-zA-Z\s]+$/,'Please enter only alphabets'),
  email: yup
  .string()
  .min(9, 'Enter a valid email')
  .max(50, 'Enter a valid email')
  .required('Enter an email')
  .matches(/^[a-zA-Z0-9._@]+$/, 'Enter a valid email'),
  acceptterms: yup
  .boolean()
  .oneOf([true], "You must accept the terms and conditions")
  .required('Accept terms and conditions'),
  acceptprivacypolicy: yup
  .boolean()
  .oneOf([true], "You must accept the privacy policy")
  .required('Accept privacy policy')

});

export default function AgentSignUp() {
  const navigate = useNavigate();
  const[formError, setFormError] = useState('');
  const [loading, setLoading] = React.useState(false);
  

  useEffect(() => {
    logEvent(analytics, 'Sign Up', {
content_type: 'page',
content_id: 'signup'
});
}, []);
  const formik = useFormik({
    initialValues: {
      firstname: '',
      lastname: '',
      email: '',
      mobile:'',
      address:'',
      country:'',
      acceptterms:false,
      acceptprivacypolicy: false

    },
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
     //console.log("values", values)
     setLoading(true);
     const expensesListResp = async () => {

      await axios.post(DEFAULTS.API_URL + 'agent', values)
  .then(response => {
    console.log("response", response.data);
    if (response.data.status === 'success') {
      const data = { "from": 'AGENT-SIGNUP' };
      navigate('/auth/confirm-email', { state: data });
    } else {
      setLoading(false);
      setFormError(response.data.msg);
    }
  })
  .catch(error => {
    // Handle errors here
    setLoading(false); // Stop the loading indicator on error
    if (error.response) {
      // The server responded with a status other than 2xx
      const statusCode = error.response.status;

       setFormError("An error occurred. Please try again.");

    } else {
      // Something else caused the error
      setFormError("An unexpected error occurred. Please try again.");
    }
  });
        
    }
    expensesListResp()
    },
  });
const handleLabelClickRefund = () => {
    // Handle the click event as if it were a link click
    window.open(DEFAULTS.REFUND, '_blank'); // Opens the link in a new tab
  };
  const handleLabelClickTerms = () => {
    // Handle the click event as if it were a link click
    window.open(DEFAULTS.AGENT_TERMS, '_blank'); // Opens the link in a new tab
  };

  const handleLabelClickPrivacy = () => {
    // Handle the click event as if it were a link click
    window.open(DEFAULTS.AGENT_PRIVACY, '_blank'); // Opens the link in a new tab
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={8}
          sx={{
            backgroundImage: 'url(https://ikeocean.com/wp-content/uploads/2020/05/png-Road-Cargo.png)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={4} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {/* <LogoComponent/> */}
            <Typography component="h1" variant="h5">
            Agent Sign Up
          </Typography>
          <Box component="form" onSubmit={formik.handleSubmit} sx={{ mt: 3 }}>
            {(formError.length > 0) ? 
            <Alert color='error' style={{marginBottom:20}}>{formError}</Alert> : ''}
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="firstname"
                  fullWidth
                  id="firstname"
                  label="First Name"
                  autoFocus
                  value={formik.values.firstname}
                  onChange={formik.handleChange}
                  error={formik.touched.firstname && Boolean(formik.errors.firstname)}
                  helperText={formik.touched.firstname && formik.errors.firstname}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="lastname"
                  label="Last Name"
                  name="lastname"
                  value={formik.values.lastname}
                  onChange={formik.handleChange}
                  error={formik.touched.lastname && Boolean(formik.errors.lastname)}
                  helperText={formik.touched.lastname && formik.errors.lastname}
                />
              </Grid>
              
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="mobile"
                  label="Mobile"
                  name="mobile"
                  value={formik.values.mobile}
                  onChange={formik.handleChange}
                  error={formik.touched.mobile && Boolean(formik.errors.mobile)}
                  helperText={formik.touched.mobile && formik.errors.mobile}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="address"
                  label="State"
                  name="address"
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  error={formik.touched.address && Boolean(formik.errors.address)}
                  helperText={formik.touched.address && formik.errors.address}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="country"
                  label="Country"
                  name="country"
                  value={formik.values.country}
                  onChange={formik.handleChange}
                  error={formik.touched.country && Boolean(formik.errors.country)}
                  helperText={formik.touched.country && formik.errors.country}
                />
              </Grid>
              <Grid item xs={12}>
              <FormControlLabel
  control={
    <Checkbox
      value={formik.values.acceptterms}
      id="acceptterms"
      name="acceptterms"
      checked={formik.values.acceptterms}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      color="primary"
    />
  }
  label={
    <Typography
      variant="body2"
      color="primary"
      style={{ cursor: 'pointer', textDecoration: 'underline' }}
      onClick={handleLabelClickTerms}
    >
      I have read and agree to the{' '}
      <span style={{ textDecoration: 'underline' }}> terms and conditions</span>
    </Typography>
  }
/>
{formik.touched.acceptterms && formik.errors.acceptterms && (
  <FormHelperText error>{formik.errors.acceptterms}</FormHelperText>
)}
              </Grid>
 
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox 
                    value={formik.values.acceptprivacypolicy}
                    checked={formik.values.acceptprivacypolicy}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    id="acceptprivacypolicy"
                    name="acceptprivacypolicy"
                    color="primary" />}
                  label={
                    <Typography
                      variant="body2"
                      color="primary"
                      style={{ cursor: 'pointer', textDecoration: 'underline' }}
                      onClick={handleLabelClickPrivacy}
                    >
                      I have read and agree to the{' '}
                      <span style={{ textDecoration: 'underline' }}> privacy policy</span>
                    </Typography>
                  }
                />
                {formik.touched.acceptprivacypolicy && formik.errors.acceptprivacypolicy && (
  <FormHelperText error>{formik.errors.acceptprivacypolicy}</FormHelperText>
)}
              </Grid> 
            </Grid>

        <LoadingButton
              type="submit"
              fullWidth
              loading={loading}
              variant="contained"
              disabled={loading}
              sx={{ mt: 3, mb: 2 }}
              style={{backgroundColor:'#eb4034'}}

            >
              Sign Up
              </LoadingButton>
            
          </Box>
          </Box>
        </Grid>
      </Grid>

      
    </ThemeProvider>
  );
}