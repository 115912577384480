import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import { Container, Modal,Card, CardContent, Alert, RadioGroup, FormControlLabel, Radio,LinearProgress, TextField, Button, Typography, Box } from '@mui/material';
import { BrowserRouter, Routes, Route,Outlet, useNavigate } from "react-router-dom";

import {
  fetchUserDataRequest,
  fetchUserDataSuccess,
  fetchUserDataFailure
} from '../../../../redux/features/userSlice';
import { DEFAULTS } from '../../../../Constants/Constants'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const  MainLoader = () => {
  const dispatch = useDispatch();
  const token = localStorage.getItem('user-token');
  const [minimumLoadingComplete, setMinimumLoadingComplete] = useState(false);
  let navigate = useNavigate();
  // Retrieve usertype from router.query safely
  


  // Select data from Redux store

  const { loading: userLoading, error: userError } = useSelector(
    (state: RootState) => state.appuserdata
  );
  
  const fetchData = async (
    url: string,
    requestAction: any,
    successAction: any,
    failureAction: any
  ) => {
    dispatch(requestAction());
    try {
      //const response = await fetch(url);
      const response = await fetch(url, {
        method: 'GET', // or 'POST', depending on your request
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const result = await response.json();
      console.log('Fetched data:', result);
      dispatch(successAction(result));
    } catch (err: any) {
      console.error('Fetch error:', err);
      dispatch(failureAction(err.message));
    }
  };

  useEffect(() => {
    // Start a 5-second minimum loading timer
    const timer = setTimeout(() => {
      setMinimumLoadingComplete(true);
    }, 5000);

    // Cleanup the timer
    return () => clearTimeout(timer);
  }, []);



  useEffect(() => {
    
    fetchData(
        DEFAULTS.API_URL+'profile',
      fetchUserDataRequest,
      fetchUserDataSuccess,
      fetchUserDataFailure
    );
  }, [dispatch]);


  useEffect(() => {
    // Navigate to home page after all loading states are complete and the minimum loading time has passed
    if (
      !userLoading &&
      !userError &&
      minimumLoadingComplete
    ) {
        console.log("navigating.....")
        navigate('/portal/dashboard')
        
      
    }
  }, [
    userLoading,
    userError,
    minimumLoadingComplete,
  ]);

  const isLoading =  userLoading ||  !minimumLoadingComplete;
  const errorMessage =  userError ;
 const [open, setOpen] = React.useState(true);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
  return (
    <div style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
      {isLoading ? (
        


        <Modal
          open={open}
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              handleClose();
            }
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              We are loading your profile. Please wait...
            </Typography>
            <LinearProgress color="secondary" />
            
          </Box>
        </Modal>
      ) : (
        <p style={{ color: errorMessage ? 'red' : 'black' }}>
          {errorMessage ? `Error: ${errorMessage}` : 'Redirecting to Home...'}
        </p>
      )}
    </div>
  );
};

export default MainLoader;
